import axios from '../../utils/Axios';

export const fetchOfferBySlug = async (slug) => {
  try {
    const response = await axios.get(`/offers/${slug}`);
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
