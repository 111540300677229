import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import ApplyModalImgSrc from '../../../images/apply-modal-img.png';
import './ApplyModal.scss';
import { Button } from '../../../components/Button';
import { uploadApplication } from '../../../services/applications/uploadApplication';
import { useApplyForm } from '../../../hooks/useApplyForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ApplyModalPropTypes from '../../../types/applyModalType';

export const ApplyModal = ({
  isModalOpenModal,
  closeModal,
  openModal,
  offerId,
  companyId,
}: ApplyModalPropTypes) => {
  const {
    firstname,
    lastname,
    email,
    phone,
    application,
    message,
    isCheckBoxChecked,
    setFirstname,
    setLastname,
    setEmail,
    setPhone,
    setApplication,
    setMessage,
    setIsCheckBoxChecked,
  } = useApplyForm();

  const [isUploading, setIsUploading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);

  const goToPrivacyPolicy = () => {
    window.location.href = '/charte-vie-privee';
  };

  const handleFileChange = (e) => {
    setApplication(e?.target?.files?.[0]);
  };

  const handleCheckboxChange = (event) => {
    setIsCheckBoxChecked(event.target.checked);
  };

  const validateForm = () => {
    return (
      firstname.trim() !== '' &&
      lastname.trim() !== '' &&
      email.trim() !== '' &&
      phone.trim() !== ''
    );
  };

  const apply = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    await uploadApplication({
      firstname,
      lastname,
      email,
      phone,
      message,
      offerId,
      companyId,
      application,
      isCheckBoxChecked,
    });

    setIsUploading(false);
    setHasUploaded(true);

    setTimeout(() => {
      closeModal();
    }, 1500);
  };

  return (
    <Modal
      show={isModalOpenModal}
      size='xl'
      onHide={closeModal}
      centered
      className='apply-modal'
    >
      {hasUploaded ? (
        <div className='check-sign-container'>
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
      ) : (
        <form onSubmit={apply} encType='multipart/form-data'>
          <Modal.Header closeButton />
          <Modal.Body className='modal-body'>
            <div className='img-container'>
              <img src={ApplyModalImgSrc} />
            </div>
            <div className='apply-form'>
              <h2>Soumettez vos informations</h2>
              <TextField
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                className='text-input'
                variant='outlined'
                label='Prénom'
                required
              />
              <TextField
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                className='text-input'
                variant='outlined'
                label='Nom'
                required
              />
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='text-input'
                variant='outlined'
                label='Email'
                type='email'
                required
              />
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className='text-input'
                variant='outlined'
                label='Téléphone'
                type='phone'
                required
              />
              <TextareaAutosize
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className='text-input text-area'
                placeholder='Laissez un message'
                minRows={3}
              />
              <TextField
                onChange={handleFileChange}
                className='text-input'
                variant='outlined'
                helperText='CV'
                type='file'
              />

              <FormControlLabel
                className='checkbox-box'
                value={isCheckBoxChecked}
                control={<Checkbox onChange={handleCheckboxChange} />}
                label={
                  <Typography>
                    <span className='footer-text'>
                      {' '}
                      Je souhaite que mon CV soit conservé par Yaja dans sa CVthèque pour me proposer d'autres offres d'emplois conformément à sa</span> <span className='footer-link' onClick={goToPrivacyPolicy}>Charte Vie Privée</span>
                  </Typography>
                }
                labelPlacement='end'
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='footer'>
              <p className='footer-text-link'>
                Yaja traite les données recueillies pour répondre à votre
                demande conformément à sa{' '}
                <a href='/charte-vie-privee' className='footer-link'>
                  Charte Vie Privée
                </a>
              </p>
            </div>
            <Button
              type='submit'
              label='Postuler'
              className='submit'
              isLoading={isUploading}
              loadingSize={85}
              disabled={!validateForm()}
            />

            <div className='mobile-footer'>
              <p className='footer-text-mobile'>
                Yaja traite les données recueillies pour répondre à votre
                demande conformément à sa {''}
                <a href='/charte-vie-privee' className='footer-link'>
                  Charte Vie Privée
                </a>
              </p>
            </div>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};
