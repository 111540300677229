import { useCallback, useEffect, useState } from 'react';

export const useAutoTyper = ({
  textRefs,
  active,
  writeInterval,
  deleteInterval,
  delayToWrite,
  delayToDelete,
}) => {
  const [autoTyper, setAutoTyper] = useState('');
  const [whichFuncStart, setWhichFuncStart] = useState(true);
  const [textRef, setTextRef] = useState(textRefs[0]);
  const [counter, setCounter] = useState(0);

  const letterWriter = useCallback(() => {
    const text = textRef.slice(0, autoTyper.length + 1);

    setAutoTyper(text);
  }, [autoTyper, textRef]);

  const letterRemover = useCallback(() => {
    const text = autoTyper.slice(0, -1);

    setAutoTyper(text);
  }, [autoTyper]);

  const writer = useCallback(() => {
    setTimeout(() => {
      if (autoTyper.length === textRef.length) {
        setTimeout(() => {
          setWhichFuncStart(true);
        }, delayToDelete);

        return;
      }

      letterWriter();
    }, writeInterval);
  }, [autoTyper, textRef, writeInterval, delayToDelete, letterWriter]);

  const remover = useCallback(() => {
    setTimeout(() => {
      if (autoTyper.length === 0) {
        const tmpCounter = (counter + 1) % textRefs.length;

        setCounter(tmpCounter);
        setTextRef(textRefs[tmpCounter]);

        setTimeout(() => {
          setWhichFuncStart(false);
        }, delayToWrite);

        return;
      }

      letterRemover();
    }, deleteInterval);
  }, [autoTyper, delayToWrite, deleteInterval, letterRemover]);

  useEffect(() => {
    if (active) {
      if (whichFuncStart === false) {
        writer();
      }
      if (whichFuncStart === true) {
        remover();
      }
    }
  }, [active, whichFuncStart, writer, remover]);

  return { autoTyper };
};