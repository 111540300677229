import { useEffect, useState } from 'react';
import { fetchRelatedOffers } from '../services/offers/fetchRelatedOffers';
import OfferData from '../types/offers';

type UseFetchOffersReturnType = {
  offers: OfferData[];
};
export const useFetchOtherOffers = (offerId): UseFetchOffersReturnType => {
  const [offers, setOffers] = useState<OfferData[]>([]);

  useEffect(() => {
    if (!offerId) {
      return;
    }

    const query = {
      offerId,
    };

    fetchRelatedOffers(query).then((fetchedOffers) => {
      setOffers(fetchedOffers);
    });
  }, [offerId]);

  return { offers };
};
