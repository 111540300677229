import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoSrc from '../../images/logo.png';
import './YajaNavbar.scss';

export const YajaNavbar = () => (
    <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" className="yaja-navbar">
        <div className='nav-content'>
            <Navbar.Brand href="/">
                <img src={logoSrc}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto" />
                <Nav>
                    <Nav.Link className="nav-link" href="/offers">Nos offres</Nav.Link>
                    <Nav.Link className="nav-link" eventKey={2} href="/whoweare">
                        Qui sommes-nous
                    </Nav.Link>
                    <Nav.Link className="nav-link contact-us" eventKey={4} href="/contactus">
                        Nous contacter
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
    </Navbar>
);