import axios from '../../utils/Axios';
import qs from 'qs';

export const fetchRelatedOffers = async (params) => {
  try {
    const queryParams = qs.stringify(params);
    const response = await axios.get(`/offers/related?${queryParams}`);

    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};