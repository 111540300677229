import React from 'react';
import './Card.scss';

export const Card = ({ children, className = '', onClick = null, href = null, target = null }) => {
  if (href) {
    return (
      <div onClick={onClick} className={`yaja-card ${className || ''}`}>
        <a target={target} href={href}> {children}</a>
      </div>
    );
  }
  return (
    <div onClick={onClick} className={`yaja-card ${className || ''}`}>
      {children}
    </div>
  );
};
