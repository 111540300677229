import { useEffect, useState } from 'react';
import { fetchMostFrequentCategories } from '../services/categories/getMostFrequentCategories';

export const useFetchFrequentCategories = () => {
  const [frequentCategories, setFrequentCategories] = useState([]);

  useEffect(() => {
    fetchMostFrequentCategories().then((fetchedFrequentCategories) => {
      setFrequentCategories(fetchedFrequentCategories);
    });
  }, []);

  return { frequentCategories };
};
