import { useEffect, useState } from 'react';
import { fetchOffersCount } from '../services/offers/fetchNumberOfOffers';

export const useFetchOffersCount = () => {
  const [offersCount, setOffersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchOffersCount().then((fetchedOffersCount) => {
      setOffersCount(fetchedOffersCount);
      setIsLoading(false);
    });
  }, []);

  return { offersCount, isLoading };
};
