import React from 'react';

const AnimatedNodesComponent = () => {
  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <div style={{ display: 'none' }}>
        <div className="gradient-1"></div>
        <div className="gradient-2"></div>
        <div className="background"></div>
        <div className="nodes">
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>
          <div className="node-background"></div>

          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
          <div className="node-text"></div>
        </div>
      </div>

      <canvas></canvas>
    </div>
  );
};

export default AnimatedNodesComponent;