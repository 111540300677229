import { ClipLoader } from 'react-spinners';
import './Button.scss';

export const Button = ({
  type = 'button',
  label,
  href = '',
  className = '',
  onClick = () => {},
  isLoading = false,
  loadingSize = null,
  disabled = false,
}) => {
  const buttonStyle = {
    width: loadingSize || undefined,
  };

  if (type === 'link') {
    return (
      <a
        onClick={isLoading ? null : onClick}
        style={buttonStyle}
        className={`yaja-button ${disabled ? 'disabled' : ''} ${
          isLoading ? 'disabled' : ''
        } ${className || ''}`}
        href={href}
      >
        {isLoading ? (
          <ClipLoader
            color='#fff'
            loading={true}
            size={15}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        ) : (
          label
        )}
      </a>
    );
  }

  if (type === 'button') {
    return (
      <button
        onClick={isLoading ? null : onClick}
        style={buttonStyle}
        className={`yaja-button ${disabled ? 'disabled' : ''} ${
          isLoading ? 'disabled' : ''
        } ${className || ''}`}
        type='button'
      >
        {isLoading ? (
          <ClipLoader
            color='#fff'
            loading={true}
            size={15}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        ) : (
          label
        )}
      </button>
    );
  }

  return (
    <button
      onClick={isLoading ? null : onClick}
      style={buttonStyle}
      className={`yaja-button ${disabled ? 'disabled' : ''} ${
        isLoading ? 'disabled' : ''
      } ${className || ''}`}
      type='submit'
    >
      {isLoading ? (
        <ClipLoader
          color='#fff'
          loading={true}
          size={15}
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      ) : (
        label
      )}
    </button>
  );
};