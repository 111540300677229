import { useEffect, useState } from 'react';
import { fetchNumbers } from '../services/numbers/fetchNumbers';

export const useNumbers = () => {
  const [recruitmentsCount, setRecruitmentsCount] = useState(0);
  const [freelancesCount, setFreelancesCount] = useState(0);
  const [clientsCount, setClientsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchNumbers().then((fetchedNumbers) => {
      if (fetchedNumbers?.recruitments) {
        setRecruitmentsCount(fetchedNumbers.recruitments);
      }

      if (fetchedNumbers?.freelances) {
        setFreelancesCount(fetchedNumbers.freelances);
      }

      if (fetchedNumbers?.clients) {
        setClientsCount(fetchedNumbers.clients);
      }

      setIsLoading(false);
    });
  }, []);

  return { recruitmentsCount, freelancesCount, clientsCount, isLoading };
};
