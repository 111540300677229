import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import './FormInputAndButton.scss';

export const FormInputAndButton = ({ onValidate, placeholder, noPlaceholder, searchString = null}) => {
  const [input, setInput] = useState('');

  const checkEnterKey = (event) => {
    if (event.key === 'Enter') {
      onValidate(input);
    }
  };

  useEffect(() => {
    if (searchString) {
      setInput(searchString);
    }
  }, [searchString])
 
  return (
        <div className="form-input-and-button">
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder={placeholder || (noPlaceholder ? '' : 'Javascript')}
                    aria-label="Javascript"
                    aria-describedby="basic-addon2"
                    className='rounded-input'
                    onKeyUp={checkEnterKey}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                />

                <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    className='rounded-button'
                    onClick={() => onValidate(input)}
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </Button>
            </InputGroup>
        </div>
  );
};