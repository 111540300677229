import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { YajaNavbar } from './components/YajaNavbar';
import { RouterProvider } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { YajaFooter } from './components/YajaFooter';
import { Analytics } from '@vercel/analytics/react';
import { Suspense } from 'react';
import { router } from './router';
import { Offer } from './pages/Offer';

const App = () => {
  return (
    <div className='App'>
      <YajaNavbar />
      <ParallaxProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} />
        </Suspense>
      </ParallaxProvider>
      <YajaFooter />
      <Analytics />
    </div>
  );
};

const OfferSinglePage = () => {
  return (
    <div className='App'>
      <YajaNavbar />
      <ParallaxProvider>
        <Offer />
      </ParallaxProvider>
      <YajaFooter />
      <Analytics />
    </div>
  );
};

export { OfferSinglePage };
export default App;
