import { useState } from 'react';
import './Offer.scss';
import htmlParser from 'html-react-parser';
import { Helmet } from "react-helmet";
import { convert } from 'html-to-text';
import { getCleanDate } from '../../utils/getCleanDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign, faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { useFetchOfferBySlug } from '../../hooks/useFetchOfferBySlug';
import { Button } from '../../components/Button';
import { ApplyModal } from './ApplyModal';
import OffersCarousel from './OffersCarousel/OffersCarousel';
import { useFetchOtherOffers } from '../../hooks/useFetchOtherOffers';
import { FacebookIcon, FacebookShareButton, TwitterShareButton, TwitterIcon, LinkedinIcon } from 'react-share';
import { Placeholder } from 'react-bootstrap';

export const Offer = () => {
  let params = new URL(window.location.href).searchParams;
  const { offer } =  useFetchOfferBySlug(params.get('slug'));
  const [isModalOpenModal, setIsModalOpenModal] = useState(false);
  const { offers: otherOffers } = useFetchOtherOffers(offer?._id);
  const closeModal = () => setIsModalOpenModal(false);
  const openModal = () => setIsModalOpenModal(true);
  const bgImage = `https://${window.location.hostname}/static/media/offer-page-bg.d19cc1a….jpg`;

  const url = window.location.href;
  
  return (
        <div id="offer-page">
            {offer ? <Helmet>
                <meta charSet="utf-8" />
                <title>{offer.title}</title>
                <meta property="og:type" content="article" />
                <meta name="image" property="og:image" content={bgImage} />
                <meta
                  name="description"
                  content={convert(offer.htmlDescription).substring(0, 250)}
                />
                <meta property='og:title' content={offer.title} />
                <meta property='og:description' content={convert(offer.htmlDescription).substring(0, 250)} />
                <meta property='og:url' content={window.location.href} />
            </Helmet> : null}
            <section id="top-section"></section>
            <section id="content-section">
                <div className="content-container">
                    <div className="offer-content">
                        <div className='top-section'>
                            <div className='top-section-two'>
                                {offer ? <h1 className="offer-title">{offer.title}</h1> : <Placeholder className="placeholder-offer-title" />}

                                {offer ? <p className="date">{`Le ${getCleanDate(new Date((offer).createdAt))}`}</p> : <Placeholder style={{ width: '112px', marginBottom: '10px', display: 'block' }} />}

                                <div className="multi-details">
                                    <div>
                                        <FontAwesomeIcon color="#4c7eea" icon={faGlobeAfrica} />
                                        {offer ? <p className="details">{offer.city?.toUpperCase()}, {offer.country?.toUpperCase()}</p> : <Placeholder style={{ width: '100px', marginLeft: '5px' }} />}
                                    </div>

                                    <div>
                                        <FontAwesomeIcon color="#4c7eea" icon={faEuroSign} />
                                        {offer ? <p className="details">{offer.type === 'recrutement' ? `${offer.salaryFork.min}k - ${offer.salaryFork.max}k/an` : `${offer.dailyRate}/jour`}</p> : <Placeholder style={{ width: '100px', marginLeft: '5px' }} />}
                                    </div>
                                </div>

                                <div className="categories-container">
                                    {offer ? (offer as any).categories.map(category => (
                                        <div key={`offer-category-${category.slug}`} className="category-container">{category.name}</div>
                                    )) : <><Placeholder.Button style={{ width: '75px', height: '31px', backgroundColor: 'grey', borderColor: 'grey' }} /> <Placeholder.Button style={{ width: '75px', height: '31px', backgroundColor: 'grey', borderColor: 'grey' }} /></>}
                                </div>
                            </div>
                            <div className="side-section">
                                <Button label="Postuler" className="apply-btn" onClick={openModal} />

                                <div className="share-container">
                                    <p className="share-title">PARTAGER L'OFFRE</p>
                                    <div className="icons">
                                        <FacebookShareButton url={url}>
                                           <FacebookIcon size={20} borderRadius={8}/>
                                        </FacebookShareButton>
                                        <TwitterShareButton title={'Job opportunity!'} url={url} hashtags={['joboffer', 'yaja']}>
                                            <TwitterIcon size={20} borderRadius={8}/>
                                        </TwitterShareButton>
                                        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`}>
                                            <LinkedinIcon size={20} borderRadius={8}></LinkedinIcon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className="description-title">Description du poste</h3>

                            {offer ? <div className="description">
                                {htmlParser((offer as any).htmlDescription)}
                            </div> : <>
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                                <Placeholder className="placeholder-description" />
                            </>}
                        </div>
                    </div>
                </div>
                <div className='sticky-side-section'>
                    <div className="side-section">
                        <Button label="Postuler" className="apply-btn" onClick={openModal} />

                        <div className="share-container">
                            <p className="share-title">PARTAGER L'OFFRE</p>
                            <div className="icons">
                                <FacebookShareButton url={url}>
                                    <FacebookIcon size={20} borderRadius={8}/>
                                </FacebookShareButton>
                                <TwitterShareButton title={'Job opportunity!'} url={url} hashtags={['joboffer', 'yaja']}>
                                    <TwitterIcon size={20} borderRadius={8}/>
                                </TwitterShareButton>
                                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`}>
                                    <LinkedinIcon size={20} borderRadius={8}></LinkedinIcon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="other-offers-section">
            {otherOffers.length > 0 && (
                <div id="other-offers-content">
                    <h2>QUELQUES AUTRES OFFRES LIÉES</h2>
                    <OffersCarousel offers={otherOffers} />
                </div>
            )}
            </section>

            {isModalOpenModal && offer && <ApplyModal
                isModalOpenModal={isModalOpenModal}
                closeModal={closeModal}
                openModal={openModal}
                offerId={offer._id}
                companyId={(offer.company)}
            />}
        </div>
  );
};
