import { createRoot } from 'react-dom/client';
import './index.css';
import App, { OfferSinglePage } from './App.tsx';
import reportWebVitals from './reportWebVitals.js';

const rootContainer = document.getElementById('root');
const rootOffersContainer = document.getElementById('root-offer');

if (rootContainer) {
  const root = createRoot(rootContainer as HTMLElement);
  root.render(<App/>);
}

if (rootOffersContainer) {
  const rootOffers = createRoot(rootOffersContainer as HTMLElement);
  rootOffers.render(<OfferSinglePage />);
}

// If you want to start  measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
