import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { CharteViePriveePage } from './pages/CharteViePrivee';
import { LoadingOffer } from './pages/Offer/LoadingOffer';
import Home from './pages/Home';
const CguPage = React.lazy(() => import('./pages/Cgu'));
const MentionsLegales = React.lazy(() => import('./pages/Mentions'));
const Contact = React.lazy(() => import('./pages/Contact'));
const WhoWeAre = React.lazy(() => import('./pages/WhoWeAre'));
const Offers = React.lazy(() => import('./pages/Offers'));

export const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/offers', element: <Offers /> },
  { path: '/offer', element: <LoadingOffer /> },
  { path: '/whoweare', element: <WhoWeAre /> },
  { path: '/cgu', element: <CguPage/> },
  { path: '/contactus', element: <Contact /> },
  { path: '/mentions-legales', element: <MentionsLegales /> },
  { path: '/charte-vie-privee', element: <CharteViePriveePage /> },
]);
