import Axios from 'axios';

const authAxios = () => {
//   const token = localStorage.getItem("jwt");

  const axios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    // headers: { Authorization: `Bearer ${token}` }
  });

  return axios;
};

export default authAxios();