import React, { useCallback, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './OffersCarousel.scss';
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card } from '../../../components/Card';
import './OffersCarousel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign, faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../components/Button';

const OffersCarousel = ({ offers }) => {

  return (
    <>
      <Swiper
        slidesPerView={1.4}
        spaceBetween={30}
        centeredSlides={offers.length > 3}
        pagination={{
          clickable: true,
        }}
        navigation
        modules={[Pagination]}
        className='offers-swiper'
        breakpoints={{
          500: {
            slidesPerView: 1.2,
            spaceBetween: 20,
            centeredSlides: true,
          },
          768: {
            slidesPerView: 1.4,
            spaceBetween: 20,
            centeredSlides: true,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {offers.map((offer) => (
          <SwiperSlide>
            <div className='card-container'>
              <Card className='section-2-card' onClick={() => {}}>
                <div>
                  <h3>{offer.title}</h3>

                  <div className='multi-details'>
                    <div>
                      <FontAwesomeIcon
                        className='details-icon'
                        color='#4c7eea'
                        icon={faGlobeAfrica}
                      />
                      <p className='details'>
                        {offer.country?.toUpperCase()}
                      </p>
                    </div>

                    <div>
                      <FontAwesomeIcon
                        className='details-icon'
                        color='#4c7eea'
                        icon={faEuroSign}
                      />
                      

                      {offer.type === 'recrutement' && (
                        <p className='details'>{`${offer.salaryFork.min}k - ${offer.salaryFork.max}k`}</p>
                      )}

                      {offer.type === 'freelance' && (
                        <p className='details'>{`${offer.dailyRate}`}</p>
                      )}
                    </div>
                  </div>

                  <div className='categories-container'>
                    {offer.categories.map((category) => (
                      <div className='category-container'>{category.name}</div>
                    ))}
                  </div>
                </div>

                <div className='btn-container'>
                  <Button
                    type='link'
                    label="Voir l'offre"
                    href={`/offer?slug=${offer.slug}`}
                    className='see-offer-btn'
                    isLoading={false}
                    loadingSize={106}
                  />
                </div>
              </Card>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default OffersCarousel;
