import './CharteViePrivee.scss';

export const CharteViePriveePage = () => {
  return (
    <div className='charte-page'>
      <div className='charte-container'>
        <h1 className='title'>Charte Vie Privée</h1>
        <ol>
          <li>
            <h6>Informations légales</h6>
            <p>
              La présente Charte Vie Privée fait partie intégrante des <a href='/cgu'>CGU</a> si bien que les définitions
              utilisées dans ces dernières sont réutilisées dans la présente
              Charte Vie Privée.
            </p>
            <p>
              La présente Charte Vie Privée a pour objet d’informer les
              Internautes et les Contacts sur la façon dont leurs Données
              Personnelles sont collectées, comment celles-ci sont traitées par
              Yaja et enfin les droits dont bénéficient les Personnes Concernées
              quant à ces traitements tels qu’ils sont définis ci-après.
            </p>
          </li>
          <li>
            <h6>Définitions</h6>{' '}
            <p>
              En complément des termes employés avec une majuscule tels que
              définis dans les CGU les termes suivants qu’ils soient employés au
              singulier ou au pluriel dans la présente Charte Vie Privée, auront
              la définition suivante :
            </p>
            <ul>
              <li>
                <span className='bold-text'>Archivage Intermédiaire : </span>
                désigne le déplacement des Données Personnelles qui présentent
                encore pour Yaja un intérêt administratif (comme par exemple en
                cas de contentieux et/ou en cas d’obligation légale) dans une
                base de données distincte, séparée logiquement ou physiquement
                et dont, en tout état de cause, l’accès est restreint. Cette
                archive est une étape intermédiaire avant la suppression des
                Données Personnelles concernées ou leur anonymisation ;
              </li>
              <li>
                <span className='bold-text'>Contact : </span> désigne les
                personnes physiques dont Yaja traite les Données Personnelles
                dans le cadre de son activité et depuis le Site, et notamment
                des individuels (notamment des candidats ou des Internautes) ou
                des représentants de personnes légales (clients, partenaires,
                prestataires, etc.) ;
              </li>

              <li>
                <span className='bold-text'>Donnée(s) (Personnelle(s)) :</span>{' '}
                désigne(nt) les données personnelles renseignées par un Contact
                dans le cadre des Services et/ou collectées par Yaja au sens du
                Règlement (UE) 2016/679 relatif à la protection des personnes
                physiques à l&#39;égard du traitement des données à caractère
                personne et à la libre circulation de ces données (règlement
                général sur la protection des données ou RGPD) et traitées par
                Yaja dans le cadre de son activité et/ou de la consultation du
                Site, comme, le cas échéant, la réalisation des Services, en
                particulier la recherche d’un candidat et/ou le traitement d’une
                réponse à une offre d’emploi, et ce dans les conditions et
                modalités telles que définies dans la Charte Vie Privée ;
              </li>
              <li>
                <span className='bold-text'>Droits Spécifiques :</span>{' '}
                désignent les droits accordés par la Règlementation sur les
                Données Personnelles aux Contacts concernant le traitement de
                leurs Données Personnelles (droits d’accès, de limitation, de
                rectification, d’effacement, d’opposition, de directive
                post-mortem, etc.) ;
              </li>
              <li>
                <span className='bold-text'>
                  Règlementation sur les Données Personnelles :
                </span>{' '}
                désigne la Loi n°78-17 du 6 janvier 1978 relative à
                l&#39;informatique, aux fichiers et aux libertés, en application
                du Règlement communautaire du 27 avril 2016 publiée au Journal
                Officiel de l’Union européenne le 4 mai 2016 relatif à la
                protection des personnes physiques à l&#39;égard du traitement
                des données à caractère personnel et à la libre circulation de
                ces données (dit « RGPD » pour Règlement Général pour la
                Protection des Données) ;
              </li>
              <li>
                <span className='bold-text'>Terminal(aux) : </span> désigne(nt)
                l&#39;équipement matériel (ordinateur, tablette, smartphone,
                téléphone, etc.) utilisé par l’Internaute pour consulter ou voir
                s&#39;afficher le Site et/ou tout autre support numérique édité
                par un tiers ;
              </li>
            </ul>
          </li>
          <li>
            <h6>
              Les finalités des traitements réalisés par Yaja et leur base
              légale associée
            </h6>
            <p>
              Les Données Personnelles des Contacts sont nécessaires pour
              permettre à Yaja de/d :
            </p>
            <div className='table-container'>
              <table className='main-table'>
                <thead>
                  <tr>
                    <th>Finalités</th>
                    <th>Base légale</th>
                    <th>Durées de conservation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className='bold-text'>
                        {' '}
                        La recherche de profils pertinents :{' '}
                      </span>
                      Site de Yaja permettant le dépôt de candidatures (spontanées
                      ou en réponse à une offre d’emploi), utilisation d’autres
                      sites proposant des offres d’emploi sur Internet aux
                      candidats (p. ex. : Pôle emploi, Indeed, APEC), etc.
                    </td>
                    <td>Intérêt légitime de Yaja</td>
                    <td rowSpan={4} className='merged-cell'>
                      <p>
                        Jusqu’à trois mois après l’aboutissement du processus de
                        recrutement : conservation des candidatures non retenues
                        afin de pouvoir expliquer les raisons de cette décision.
                      </p>
                      <p>
                        Si le candidat y consent, il est possible de conserver les
                        données après la prise de décision concernant l’embauche
                        pour une durée de deux ans à compter du dernier contact
                        avec le candidat.
                      </p>
                      <p>
                        A l’expiration de cette durée de deux ans, cette opération
                        pourra être renouvelée avec l’accord du candidat.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold-text'>
                        {' '}
                        La constitution d’une CVthèque{' '}
                      </span>
                      ou l’élaboration d’une base de données de type « vivier de
                      candidatures »
                    </td>
                    <td>Consentement</td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold-text'>
                        La présélection des candidats :{' '}
                      </span>
                      tri, enregistrement et classement des CV et des lettres de
                      motivation sous forme papier ou dans une base de données, et
                    </td>
                    <td>Exécution du contrat (mesures précontractuelles)</td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold-text'>
                        La prise de contact avec le candidat pour évaluer la
                        capacité du candidat à occuper un emploi et mesurer ses
                        aptitudes professionnelles :
                      </span>{' '}
                      traitement des informations collectées à l’occasion des
                      entretiens téléphoniques, en face à face, etc.
                    </td>
                    <td>
                      Intérêt légitime de Yaja et Exécution du contrat (mesures
                      précontractuelles)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold-text'>
                        <p>Gestion des contrats</p>{' '}
                        <p>Gestion de l’exécution des (s/S)ervices, etc.</p>{' '}
                      </span>
                    </td>
                    <td>Exécution du contrat</td>
                    <td>
                      Les Données Personnelles relatives à un Contact sont
                      conservées en base active pendant une durée de trois (3)
                      ans, lequel délai démarre, pour les Données Personnelles
                      concernées, à compter de la finalisation d’un contrat avec
                      la personne physique ou la personne morale qu’il représente
                      et/ou à compter d’une période d’inactivité de deux (2) ans.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold-text'>
                        Tenue de la comptabilité
                        <p>Obligations comptables, fiscales, etc.</p>
                      </span>
                    </td>
                    <td>
                      Respect d’une obligation légale de conservation des données
                      (par exemple, l’obligation de s&#39;assurer de
                      l&#39;identité de la personne en demandant la fourniture
                      d&#39;un justificatif d&#39;identité)
                    </td>
                    <td>
                      Sous la forme d’archive intermédiaire : durée légale de
                      conservation (par exemple, obligation comptable de 10 ans).
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='nested-table-row'>
                      <table className='table-bordered'>
                        <tbody>
                          <tr>
                            <td className='first-column'>
                              <span className='bold-text'>
                                {' '}
                                Suivi de la relation client
                              </span>
                            </td>
                            <td className='second-column'>
                              <p>Enquêtes de satisfaction</p>
                              <p>Gestion des réclamations</p>
                            </td>
                            <td className='third-column'>
                              <p>Intérêt légitime de Yaja</p>{' '}
                              <p>Exécution du contrat</p>
                            </td>
                            <td className='fourth-column'>
                              <p>
                                Durée nécessaire pour la réalisation de
                                l&#39;objectif de l&#39;enquête ou jusqu&#39;à
                                l&#39;exercice du droit d&#39;opposition ou le
                                retrait du consentement
                              </p>
                              <p>
                                Les Données Personnelles relatives à un Contact
                                pendant une durée de trois (3) ans, lequel délai
                                démarre, pour les Données Personnelles concernées,
                                à compter de la finalisation d’un contrat avec la
                                personne physique ou la personne morale qu’il
                                représente à compter d’une période d’inactivité de
                                deux (2) ans.
                              </p>
                              <p>
                                Si nécessaire, une période d’Archivage
                                Intermédiaire peut intervenir afin de conserver
                                les données à des fins probatoires, jusqu’à
                                écoulement des délais de prescription.
                              </p>
                              <p>
                                En cas de contentieux, les Données Personnelles
                                sont conservées jusqu’à résolution du contentieux
                                ou écoulement des délais de prescription.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className='bold-text'>
                        Lutte contre la fraude et mesures de sécurisation
                      </span>
                    </td>
                    <td>Intérêt légitime de Yaja</td>
                    <td>
                      La journalisation des logs de connexion des Internautes est
                      conservée six (6) mois.
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='nested-table-row'>
                      <table className='table-bordered'>
                        <tbody>
                          <tr>
                            <td
                              className='first-column merged-cell-2'
                              rowSpan={2}
                            >
                              <span className='bold-text'>
                                Sélection de clients / Études / Enquêtes
                              </span>
                            </td>
                            <td className='second-column'>
                              <p>Études sur la qualité des Services</p>
                            </td>
                            <td className='third-column'>
                              <p>Intérêt légitime de Yaja</p>{' '}
                            </td>
                            <td className='fourth-column'>
                              <p>
                                Durée nécessaire pour la réalisation de
                                l&#39;objectif de l&#39;étude ou jusqu&#39;à
                                l&#39;exercice du droit d&#39;opposition ou le
                                retrait du consentement
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className='second-column'>
                              <p>Statistiques de vente</p>
                            </td>
                            <td className='third-column'>
                              <p>Intérêt légitime de Yaja</p>{' '}
                            </td>
                            <td className='fourth-column'>
                              <p>
                                Durée nécessaire pour la réalisation de
                                l&#39;objectif visé par les statistiques ou
                                jusqu&#39;à l&#39;exercice du droit
                                d&#39;opposition
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='nested-table-row'>
                      <table className='table-bordered'>
                        <tbody>
                          <tr>
                            <td className='first-column' rowSpan={3}>
                              <span className='bold-text'>
                                Actions de prospection commerciale, (messages
                                publicitaires, jeux concours, parrainage,
                                promotion, etc.).
                              </span>
                            </td>
                            <td className='second-column'>
                              <p>
                                Par voie électronique (en vue de l’envoi de
                                courriel, SMS, système automatisé de communication
                                électronique sans intervention humaine, etc.),
                                pour des biens ou services qui n’ont pas déjà été
                                achetés par les personnes visées
                              </p>
                            </td>
                            <td className='third-column'>
                              <p>Consentement (voir art. L. 34-5 du CPCE)</p>{' '}
                            </td>
                            <td className='fourth-column' rowSpan={3}>
                              <p>
                                Jusqu&#39;au retrait du consentement ou trois (3)
                                ans à compter du dernier contact des personnes
                                avec Yaja
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className='second-column'>
                              <p>
                                Par voie postale ou système automatisé
                                d&#39;appels donnant lieu à intervention humaine
                                et appels téléphoniques
                              </p>
                            </td>
                            <td className='third-column'>
                              <p>Intérêt légitime</p>{' '}
                            </td>
                          </tr>
                          <tr>
                            <td className='second-column'>
                              <p>
                                Par voie électronique, pour des biens et services
                                analogues déjà achetés / souscrits auprès du
                                responsable de traitement
                              </p>
                            </td>
                            <td className='third-column'>
                              <p>Intérêt légitime</p>{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Les opérations exposées ci-après ne servent pas à conduire à l'établissement de profils susceptibles
              de faire apparaître des Données Personnelles dites sensibles telles que les origines raciales ou
              ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou santé et ne
              servent pas non plus à produire des décisions automatisées de la part de Yaja.
            </p>
          </li>

          <li>
            <h6>La collecte des Données Personnelles par Yaja</h6>
            <p>
              Yaja collecte les catégories de Données Personnelles suivantes que
              l’Internaute renseigne ou qui sont collectées lors de la
              navigation de l’Internaute sur le Site et liées à son utilisation
              du Site et des Services, mais également les Données Personnelles
              suivantes d’un Contact et notamment :
            </p>

            <ul>
              <li>
                <span className='bold-text'>Identité : </span>l’identification ;
                expérience professionnelle du candidat ; formation du candidat ;
                évaluation des aptitudes et compétences du candidat
              </li>
              <li>
                <span className='bold-text'>Règlement / Paiement : </span> date
                et heure du paiement réalisés ; référence de paiement ;
                factures.
              </li>
              <li>
                <span className='bold-text'>
                  Suivi de la relation commerciale :{' '}
                </span>{' '}
                Adresses IP, navigateur, historique de parcours, origine du
                contact, activité sur le site ; enregistrements des
                conversations par courriers électroniques et des courriers
                électroniques ;
              </li>
              <li>
                <span className='bold-text'>En vue de sollicitations : </span>
                réponses aux offres d’emplois communiquées par Yaja,
              </li>
            </ul>
            <p>
              Exemples de collecte directe de données :le candidat transmet
              directement ses données, quel qu’en soit le moyen : envoi d’un CV
              et / ou d’une lettre de motivation, communication d’un
              justificatif (diplôme, certificat de travail, etc.), remplissage
              d’un formulaire, réponses apportées aux questions posées lors d’un
              entretien, etc. ; informations, vraies ou supposées, que Yaja
              infère ou produit lui- même à partir des données fournies par le
              candidat. Par exemple : compte-rendu ou évaluation rédigés par
              Yaja à l’issue d’un entretien, résultats d’interprétation d’un
              test ou d’une mise en situation, etc.
            </p>
            <p>
              Exemples de collecte indirecte de données : prise des références
              auprès d’un ancien partenaire commercial (employeur, collègue,
              client, etc.) du candidat ; informations obtenues sur les réseaux
              sociaux professionnels ; informations obtenues via un cabinet de
              recrutement.
            </p>
            <p>
              Pour répondre à ces finalités de recrutement, seules des
              informations personnelles strictement nécessaires à l’appréciation
              de la capacité du candidat à occuper l’emploi proposé ou à la
              mesure de ses aptitudes professionnelles lui seront demandées
              pendant la phase de sélection.
            </p>
            <p>
              À cette fin, Yaja demandera des données relatives aux diplômes
              obtenus, aux expériences professionnelles, aux compétences et
              aptitudes professionnelles en lien avec le poste proposé. La
              non-fourniture de ces données rendra impossible la participation
              au processus de recrutement. Dans le cas où une candidature serait
              retenue pour la conclusion d’un contrat, Yaja pour demander des
              informations et justificatifs nécessaires à l’accomplissement des
              formalités obligatoires. La transmission de ces informations
              conditionne la conclusion du contrat de travail pour les candidats
              retenus. En outre, la communication de certaines catégories
              d’information et justificatifs (état civil, domicile, numéro
              d’immatriculation à la sécurité sociale, régularité de séjour,
              etc.) a un caractère réglementaire pour les candidatures retenues.
              En conséquence, la non-fourniture de ces données rendra impossible
              la conclusion d’un contrat.
            </p>
          </li>
          <li>
            <h6>Sécurité des Données Personnelles par Yaja</h6>
            <p>
              Le Site est hébergé auprès de la société Vercel Inc. dont les coordonnées
              sont disponibles en <a href='/mentions-legales'>cliquant ici</a>.</p>
            <p>
              Toutes les précautions ont été prises pour stocker les Données
              Personnelles des Contacts dans un environnement sécurisé et
              empêcher qu’elles soient déformées, endommagées ou que des tiers
              non autorisés y aient accès.
            </p>
          </li>
          <li>
            <h6>
              Destinataires ou catégories de destinataire des Données
              Personnelles
            </h6>
            <p>
              Les Données Personnelles des Contacts sont traitées par le
              personnel habilité de Yaja et pour les finalités rappelées
              ci-avant.
            </p>
            <p>
              Les Données Personnelles des Contacts peuvent être communiquées à
              des prestataires tels que :
            </p>
            <ul>
              <li>
                les prestataires d’hébergement et de maintenance du Site ;
              </li>
              <li>
                les prestataires de solutions marketing et de gestion de
                prospection commerciale et de communication via les réseaux
                sociaux ;
              </li>
              <li>les prestataires de service client ;</li>
              <li>etc.</li>
            </ul>
            <p>
              Le cas échéant, tout transfert des Données Personnelles des
              Contacts en dehors de l’Espace Économique Européen sera effectué
              par Yaja moyennant des garanties appropriées, notamment
              contractuelles via des clauses contractuelles types, techniques et
              organisationnelles, en conformité avec la Règlementation sur les
              Données Personnelles.
            </p>
            <p>
              Le candidat, lorsqu’il postule à une offre d’emploi pour une société située en dehors de l’Espace
              Économique Européen ou dont les services dirigeants se trouvent en dehors de l’Espace Économique
              Européen est informé que ses Données Personnelles sont nécessairement transférées en dehors de
              l’Espace Économique Européen et que ce transfert est nécessaire à la conclusion ou à l&#39;exécution
              d&#39;un contrat conclu dans son intérêt entre Yaja et le potentiel client ou employeur.
            </p>
          </li>
          <li>
            <h6>Les droits des Contacts</h6>
            <p>
              Conformément à la Règlementation sur les Données Personnelles, le
              Conact peut, à tout moment, bénéficier des Droits Spécifiques
              suivants de/d’/à :
            </p>
            <ul>
              <li>accès,</li>
              <li>rectification,</li>
              <li>l’effacement,</li>
              <li>limitation d’un traitement,</li>
              <li>portabilité,</li>
              <li>opposition,</li>
              <li>directives post-mortem,</li>
              <li>retrait du consentement.</li>
            </ul>
            <ol type='a'>
              <li>
                <h6> Droits d’accès</h6>
                <p>
                  Le Contact a la possibilité d’obtenir de Yaja la confirmation
                  que les Données Personnelles le concernant sont ou ne sont pas
                  traitées et, lorsqu’elles le sont, l’accès auxdites Données
                  Personnelles ainsi que les informations suivantes :
                </p>
                <ol type='a'>
                  <li>les finalités du traitement ;</li>
                  <li>les catégories de Données Personnelles ;</li>
                  <li>
                    les destinataires ou catégories de destinataires auxquels
                    les Données Personnelles ont été ou seront communiquées ;
                  </li>
                  <li>
                    lorsque cela est possible, la durée de conservation des
                    Données Personnelles envisagée ou, lorsque ce n’est pas
                    possible, les critères utilisés pour déterminer cette
                    durée ;
                  </li>
                  <li>
                    l’existence du droit de demander à Yaja la rectification ou
                    l’effacement de Données Personnelles, ou une limitation du
                    traitement de ses Données Personnelles, ou du droit de
                    s’opposer à ce traitement ;
                  </li>
                  <li>
                    le droit d’introduire une réclamation auprès de la CNIL ;
                  </li>
                  <li>
                    lorsque les Données Personnelles ne sont pas collectées
                    auprès du Contact, toute information disponible quant à leur
                    source ;
                  </li>
                  <li>
                    l’existence d’une prise de décision automatisée, y compris
                    un profilage, et, au moins en pareil cas, des informations
                    utiles concernant la logique sous-jacente, ainsi que
                    l’importance et les conséquences prévues de ce traitement
                    pour le Contact ;
                  </li>
                </ol>
                <p>
                  Lorsque les Données Personnelles sont transférées vers un pays
                  tiers ou à une organisation internationale, le Contact a le
                  droit d’être informé des garanties appropriées, en ce qui
                  concerne ce transfert.
                </p>
                <p>
                  Yaja fournit une copie des Données Personnelles faisant
                  l’objet d’un traitement.
                </p>
                <p>
                  Yaja peut exiger le paiement de frais raisonnables basés sur
                  les coûts administratifs pour toute copie supplémentaire
                  demandée par le Contact.
                </p>
                <p>
                  Lorsque le Contact présente sa demande par voie électronique,
                  les informations sont fournies sous une forme électronique
                  d’usage courant, à moins qu’il ne demande qu’il en soit
                  autrement.
                </p>
                <p>
                  Le droit du Contact d’obtenir une copie de ses Données
                  Personnelles ne doit pas porter atteinte aux droits et
                  libertés d’autrui.
                </p>
              </li>
              <li>
                <h6>Droits de rectification</h6>
                <p>
                  Le Contact a la possibilité d’obtenir de Yaja, dans les
                  meilleurs délais, la rectification des Données Personnelles le
                  concernant qui sont inexactes. Il a aussi la possibilité
                  d’obtenir que les Données Personnelles incomplètes soient
                  complétées, y compris en fournissant une déclaration
                  complémentaire.
                </p>
              </li>
              <li>
                <h6>Droits à l’effacement</h6>
                <p>
                  Le Contact a la possibilité d’obtenir de Yaja l’effacement,
                  dans les meilleurs délais, de Données Personnelles le
                  concernant lorsque l’un des motifs suivants s’applique :
                </p>
                <ol type='a'>
                  <li>
                    les Données Personnelles ne sont plus nécessaires au regard
                    des finalités pour lesquelles elles ont été collectées ou
                    traitées d’une autre manière par Yaja;
                  </li>
                  <li>
                    le Contact  a retiré son consentement pour le traitement de
                    ces Données Personnelles et il n’existe pas d’autre
                    fondement juridique au traitement ;
                  </li>
                  <li>
                    le Contact exerce son droit d’opposition dans les conditions
                    rappelées ci-après et il n’existe pas de motif légitime
                    impérieux pour le traitement ;
                  </li>
                  <li>
                    les Données Personnelles ont fait l’objet d’un traitement
                    illicite ;
                  </li>
                  <li>
                    les Données Personnelles doivent être effacées pour
                    respecter une obligation légale ;
                  </li>
                  <li>
                    les Données Personnelles ont été collectées auprès d’un
                    enfant.
                  </li>
                </ol>
              </li>
              <li>
                <h6>Droits à la limitation</h6>{' '}
                <p>
                  Le Contact a la possibilité d’obtenir de Yaja la limitation du
                  traitement de ses Données Personnelles lorsque l’un des motifs
                  suivants s’applique :
                </p>
                <ol type='a'>
                  <li>
                    Yaja vérifie l’exactitude des Données Personnelles suite à
                    la contestation par le Contact  de l’exactitude des Données
                    Personnelles,
                  </li>
                  <li>
                    le traitement est illicite et le Contact  s’oppose à
                    l’effacement des Données Personnelles et exige à la place la
                    limitation de leur utilisation ;
                  </li>
                  <li>
                    Yaja n’a plus besoin des Données Personnelles aux fins du
                    traitement, mais celles-ci sont encore nécessaires au
                    Contact pour la constatation, l’exercice ou la défense de
                    droits en justice ;
                  </li>
                  <li>
                    le Contact s’est opposé au traitement dans les conditions
                    rappelées ci-après et Yaja vérifie de savoir si les motifs
                    légitimes poursuivis prévalent sur les motifs allégués.
                  </li>
                </ol>
              </li>
              <li>
                <h6>Droit à la portabilité des Données</h6>
                <p>
                  Le Contact a la possibilité de recevoir de Yaja les Données
                  Personnelles le concernant, dans un format structuré,
                  couramment utilisé et lisible par machine lorsque :
                </p>
                <ol type='a'>
                  <li>
                    le traitement des Données Personnelles est fondé sur le
                    consentement, ou sur un contrat et
                  </li>
                  <li>
                    le traitement est effectué à l’aide de procédés automatisés.
                  </li>
                </ol>
                <p>
                  Lorsque le Contact exerce son droit à la portabilité il a le
                  droit d’obtenir que les Données Personnelles soient transmises
                  directement par Yaja à un autre responsable de traitement
                  qu’il désignera lorsque cela est techniquement possible.
                </p>
                <p>
                  Le droit à la portabilité des Données Personnelles du Contact
                  ne doit pas porter atteinte aux droits et libertés d’autrui.
                </p>
              </li>
              <li>
                <h6>Droit d’opposition</h6>
                <p>
                  Le Contact peut s’opposer à tout moment, pour des raisons
                  tenant à sa situation particulière, à un traitement des
                  Données Personnelles le concernant fondé sur l’intérêt
                  légitime de Yaja. Ce dernier ne traitera alors plus les
                  Données Personnelles, à moins qu’il ne démontre qu’il existe
                  des motifs impérieux et légitimes pour le traitement qui
                  prévalent sur les intérêts et les droits et libertés du
                  Contact, ou pourra les conserver pour la constatation,
                  l’exercice ou la défense de droits en justice.
                </p>
              </li>
              <li>
                <h6>Directives post-mortem</h6>
                <p>
                  Le Contact a la possibilité de communiquer à Yaja des
                  directives relatives à la conservation, à l’effacement et à la
                  communisation de ses Données Personnelles après son décès,
                  lesquelles 8 directives peuvent être enregistrées également
                  auprès « d’un tiers de confiance numérique certifié ». Ces
                  directives, ou sorte de « testament numérique », peuvent
                  désigner une personne chargée de leur exécution ; à défaut,
                  les héritiers du Contact seront désignés.
                </p>
                <p>
                  En l’absence de toute directive, les héritiers du Contact
                  peuvent s’adresser à Yaja afin de/d’ :
                </p>

                <ul>
                  <li>
                    accéder aux traitements de Données Personnelles permettant
                    « l’organisation et le règlement de la succession du
                    défunt » ;
                  </li>
                  <li>
                    recevoir communication des « biens numériques » ou des
                    « données s’apparentant à des souvenirs de famille,
                    transmissibles aux héritiers » ;
                  </li>
                  <li>
                    faire procéder à la clôture du compte du Contact sur le Site
                    et s’opposer à la poursuite du traitement de ses Données
                    Personnelles.
                  </li>
                </ul>
                <p>
                  En tout état de cause, le Contact a la possibilité d’indiquer
                  à Yaja, à tout moment, qu’il ne souhaite pas, en cas de décès,
                  que ses Données Personnelles soient communiquées à un tiers.
                </p>
              </li>
              <li>
                <h6>Retrait du consentement</h6>
                <p>
                  Le Contact a la possibilité de retirer à tout moment son
                  consentement, soit depuis une fonction dédiée sur le Site,
                  soit en s’adressant à Yaja dans les conditions de l’article 7
                  ci-après.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6>Exercice des Droits Spécifiques des Contacts</h6>
            <p>
              Ces Droits Spécifiques peuvent être exercés, à tout moment, auprès
              de Yaja :
            </p>
            <ul>
              <li>
                Par courrier électronique à l’adresse suivante : <a href='mailto:contact@yaja.fr'>contact@yaja.fr</a>
              </li>
              <li>
                Par courrier postal à l’adresse indiquée dans les <a href='/mentions-legales'>mentions légales</a>
              </li>
            </ul>
            <p>
              Aux fins de faire valoir ses droits suivant les conditions visées
              ci-dessus et dans l’hypothèse où Yaja aurait des doutes sur
              l’auteur de la demande, Yaja pourra demander à celui-ci de
              justifier de son identité en mentionnant ses nom, prénom, adresse
              de courrier électronique et d’accompagner sa demande d’une copie
              d’une pièce d’identité en cours de validité.
            </p>
            <p>
              Une réponse sera adressée au Contact dans un délai d’un (1) mois
              maximum suivant la date de réception de la demande.
            </p>
            <p>
              Au besoin ce délai pourra être prolongé de deux (2) mois par Yaja
              qui en donnera alerte au contact, et ce, compte tenu de la
              complexité et/ou du nombre de demandes.
            </p>
            <p>
              En cas de demande du Contact de suppression de ses Données
              Personnelles et/ou en cas d’exercice de son droit à solliciter
              l’effacement de ses Données Personnelles, Yaja pourra toutefois
              les conserver sous forme d’Archivage Intermédiaire, et ce pour la
              durée nécessaire à satisfaire à ses obligations légales, ou à des
              fins probatoires pendant le délai de prescription applicable.
            </p>
            <p>
              Le Contact peut également porter réclamation devant l’autorité de
              contrôle compétente (la CNIL).
            </p>
          </li>
          <li>
            <h6>
              Les cookies déposés sur le Terminal de l’Internaute suite à sa
              navigation sur le Site
            </h6>
            <p>Aucun cookie n'est utilisé sur le Site.</p>
          </li>
        </ol>
      </div>
    </div>
  );
};
