import { ClipLoader } from 'react-spinners';
import './LoadingOffer.scss';

export const LoadingOffer = () => {
  return (
    <div className='loading-offer'>
      <ClipLoader
        color="#999"
        loading={true}
        size={55}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
