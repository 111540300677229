export enum fileTypes {
  'msword' = 'doc',
  'vnd.openxmlformats-officedocument.wordprocessingml.document' = 'docx',
  'vnd.openxmlformats-officedocument.wordprocessingml.template' = 'dotx',
  'vnd.ms-word.document.macroEnabled.12' = 'docm',
  'vnd.ms-word.template.macroEnabled.12' = 'dotm',
  'vnd.ms-excel' = 'xls',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'xlsx',
  'vnd.openxmlformats-officedocument.spreadsheetml.template' = 'xltx',
  'vnd.ms-excel.sheet.macroEnabled.12' = 'xlsm',
  'vnd.ms-excel.template.macroEnabled.12' = 'xltm',
  'vnd.ms-excel.addin.macroEnabled.12' = 'xlam',
  'vnd.ms-excel.sheet.binary.macroEnabled.12' = 'xlsb',
  'vnd.ms-powerpoint' = 'ppt',
  'vnd.openxmlformats-officedocument.presentationml.presentation' = 'pptx',
  'vnd.openxmlformats-officedocument.presentationml.template' = 'potx',
  'vnd.openxmlformats-officedocument.presentationml.slideshow' = 'ppsx',
  'vnd.ms-powerpoint.addin.macroEnabled.12' = 'ppam',
  'vnd.ms-powerpoint.presentation.macroEnabled.12' = 'pptm',
  'vnd.ms-powerpoint.template.macroEnabled.12' = 'potm',
  'vnd.ms-powerpoint.slideshow.macroEnabled.12' = 'ppsm',
}
