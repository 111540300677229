import { useState } from 'react';
import FileDetails from '../types/fileDetails';

export const useApplyForm = () => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [application, setApplication] = useState<FileDetails | null>(null);
  const [message, setMessage] = useState('');
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);

  return {
    firstname,
    lastname,
    email,
    phone,
    application,
    message,
    isCheckBoxChecked,
    setFirstname,
    setLastname,
    setEmail,
    setPhone,
    setApplication,
    setMessage,
    setIsCheckBoxChecked,
  };
};
