import { fileTypes } from '../../types/fileEnums';
import axios from '../../utils/Axios';

export const uploadApplication = async (applicationData) => {
  try {
    const formData = new FormData();
    formData.append('cv', applicationData.application);
    formData.append('firstname', applicationData.firstname);
    formData.append('lastname', applicationData.lastname);
    formData.append('email', applicationData.email);
    formData.append('phone', applicationData.phone);
    formData.append('offer', applicationData.offerId);
    formData.append('company', applicationData.companyId);
    formData.append('message', applicationData.message);
    formData.append('isCheckBoxChecked', applicationData.isCheckBoxChecked);

    if (applicationData?.application) {
      const filetype = getFileExtension(applicationData.application.type);
      const fileFormat = validateFileType(filetype);
      formData.append('cvFileFormat', fileFormat);
    } else {
      formData.append('cvFileFormat', '');
    }

    await axios.post('/applications', formData);
  } catch (error) {
    console.log(error);
  }
};

function getFileExtension(mimeType: string): string | null {
  const parts = mimeType?.split('/');
  if (parts?.length === 2) {
    return parts[1];
  }
  return null;
}

// If the file is an office document, there is a need to perform an additional check to ensure the correct extension is added to the database.
function validateFileType(type: string | null) {
  if (type === null) {
    return type;
  }

  if (type.includes('vnd.')) {
    return fileTypes[type];
  } else {
    return type;
  }
}
