import axios from '../../utils/Axios';

export const fetchMostFrequentCategories = async () => {
  try {
    const response = await axios.get('/most-populated-categories');
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
