import { useEffect, useState } from 'react';
import { fetchOfferBySlug } from '../services/offers/fetchOfferBySlug';
import OfferData from '../types/offers';

type UseFetchOffersReturnType = {
  offers: OfferData;
};

export const useFetchOfferBySlug = (slug) => {
  const [offer, setOffer] = useState<OfferData>();

  useEffect(() => {
    if (!offer && slug) {
      fetchOfferBySlug(slug).then((fetchedOffer) => {
        setOffer(fetchedOffer);
      });
    }
  }, [slug]);

  return { offer };
};
