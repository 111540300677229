import { useEffect, useState } from 'react';
import { Card } from '../../components/Card';
import team from '../../images/team.jpg';
import './Slidercards.scss';


export const Slidercards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const content = [
    {
      original: team,
      thumbnail: team,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Identification des besoins',
      span: 'Compréhension précise des exigences techniques et des compétences nécessaires.',
      span2: 'Définition du profil idéal en collaboration avec le client ou le chef de projet.',
    },
    {
      original: team,
      thumbnail: team,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Recherche et sourcing',
      span: 'Exploration de notre base de données de candidats.',
      span2: 'Utilisation d\'outils de sourcing avancés pour identifier de nouveaux talents.',
      span3: 'Approche directe des candidats potentiels.',
    },
    {
      original: team,
      thumbnail: team,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: `Présélection`,
      span: `Analyse des CV et des portfolios pour évaluer l'adéquation avec les besoins.`,
      span2: 'Entretiens préliminaires pour évaluer la motivation et la compatibilité culturelle.',
    },
    {
      original: team,
      thumbnail: team,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: `Entretiens techniques`,
      span: `Tests de codage ou de compétences techniques pour évaluer l'expertise du candidat.`,
      span2: 'Discussions approfondies sur les projets passés, les défis relevés et les solutions apportées.',
      span3: `Évaluation des compétences en résolution de problèmes et en travail d'équipe.`,
    },
    {
      original: team,
      thumbnail: team,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Présentation du candidat',
      span: `Introduction du candidat sélectionné au client ou à l'équipe concernée.`,
      span2: 'Mise en avant des compétences et des réalisations du candidat pour montrer sa valeur ajoutée.',
    },
    {
      original: team,
      thumbnail: team,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: `Intégration`,
      span: `Intégration du candidat dans l'équipe et suivi de sa progression.`,
    },
    {
      original: team,
      thumbnail: team,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Accompagnement',
      span: `Soutien continu du candidat pendant la période d'adaptation.`,
      span2: 'Feedback régulier pour assurer une transition en douceur et un succès à long terme.',
    },
  ];

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % content.length;
      return newIndex;
    });
  };
  
  useEffect(() => {
    const interval = setInterval(handleNextClick, 7000);
    return () => clearInterval(interval);
  }, [currentIndex]);
  
  return (
    <div className='slider-cards'>
      <Card className='slider-card' onClick={() => {}}>
        <div className='loading-bar'></div>
        <div className='card-content'>
          <h2> {content[currentIndex].h1}</h2>
          <p> {content[currentIndex].span} </p>
          <p> {content[currentIndex].span2} </p>
          <p> {content[currentIndex].span3} </p>
        </div>
      </Card>
    </div>
  );
};
