import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import PlaceholderLoading from 'react-placeholder-loading';
import { Parallax } from 'react-scroll-parallax';
import { Card } from '../../components/Card/index.ts';
import { FormInputAndButton } from '../../components/FormInputAndButton/FormInputAndButton.tsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from 'react-bootstrap/Button';
import altessSrc from '../../images/altesse-logo.jpeg';
import quickSignSrc from '../../images/quicksign-logo.png';
import rakutenSrc from '../../images/rakuten-logo.jpeg';
import gladySrc from '../../images/glady-logo.png';
import arrowElectronicsSrc from '../../images/arrow-electronics-logo.png';
import xmannaSrc from '../../images/xmanna.png';
import telaquaSrc from '../../images/telaqua.png';
import datasolutionSrc from '../../images/datasolution.png';
import neo9Src from '../../images/neo9.png';
import {
  faChartLine,
  faScroll,
  faStopwatch,
  faArrowRightLong,
  faQuoteRight,
  faQuoteLeft,
} from '@fortawesome/free-solid-svg-icons';
import anthonyComputerSrc from '../../images/anthonyComputer.jpg';
import sourcingCallSrc from '../../images/sourcingCall.jpg';
import meetingSrc from '../../images/meeting.jpg';
import developerComputerSrc from '../../images/developerComputer.jpg';
import introductionCandidateSrc from '../../images/introductionCandidate.jpg';
import developersComputersSrc from '../../images/developersComputers.jpg';
import meetingFeedbackSrc from '../../images/meetingFeedback.jpg';
import screenCode from '../../images/screenCode.jpg';
import blackHandShake from '../../images/blackHandShake.jpg';
import './Home.scss';
import { useAutoTyper } from '../../hooks/useAutoTyper.ts';
import ImageGallery from '../../components/ImageGallery/ImageGallery.jsx';
import { Slidercards } from '../Slidercards/Slidercards.tsx';

import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
} from 'swiper';

import { useFetchOffersCount } from '../../hooks/useFetchNumberOfOffers.ts';
import { useNumbers } from '../../hooks/useNumbers.ts';
import AnimatedNodesComponent from '../../components/AnimatedNodes/animated-nodes.tsx';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

export const Home = () => {
  const navigate = useNavigate();
  const { offersCount, isLoading: isOffersCountLoading } = useFetchOffersCount();
  const { recruitmentsCount, clientsCount, freelancesCount, isLoading: isNumbersLoading } = useNumbers();

  const images = [
    {
      original: anthonyComputerSrc,
      thumbnail: anthonyComputerSrc,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Identification des besoins',
      span: 'Compréhension précise des exigences techniques et des compétences nécessaires.',
      span2:
        'Définition du profil idéal en collaboration avec le client ou le chef de projet.',
    },
    {
      original: sourcingCallSrc,
      thumbnail: sourcingCallSrc,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Recherche et sourcing',
      span: 'Exploration de notre base de données de candidats.',
      span2:
        "Utilisation d'outils de sourcing avancés pour identifier de nouveaux talents.",
      span3: 'Approche directe des candidats potentiels.',
    },
    {
      original: developersComputersSrc,
      thumbnail: developersComputersSrc,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: `Présélection`,
      span: `Analyse des CV et des portfolios pour évaluer l'adéquation avec les besoins.`,
      span2:
        'Entretiens préliminaires pour évaluer la motivation et la compatibilité culturelle.',
    },
    {
      original: developerComputerSrc,
      thumbnail: developerComputerSrc,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: `Entretiens techniques`,
      span: `Tests de codage ou de compétences techniques pour évaluer l'expertise du candidat.`,
      span2:
        'Discussions approfondies sur les projets passés, les défis relevés et les solutions apportées.',
      span3: `Évaluation des compétences en résolution de problèmes et en travail d'équipe.`,
    },
    {
      original: introductionCandidateSrc,
      thumbnail: introductionCandidateSrc,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Présentation du candidat',
      span: `Introduction du candidat sélectionné au client ou à l'équipe concernée.`,
      span2:
        'Mise en avant des compétences et des réalisations du candidat pour montrer sa valeur ajoutée.',
    },
    {
      original: meetingFeedbackSrc,
      thumbnail: meetingFeedbackSrc,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: `Intégration`,
      span: `Intégration du candidat dans l'équipe et suivi de sa progression.`,
    },
    {
      original: meetingSrc,
      thumbnail: meetingSrc,
      originalClass: 'featured-slide',
      thumbnailClass: 'featured-thumb',
      h1: 'Accompagnement',
      span: `Soutien continu du candidat pendant la période d'adaptation.`,
      span2:
        'Feedback régulier pour assurer une transition en douceur et un succès à long terme.',
    },
  ];

  const { autoTyper } = useAutoTyper({
    textRefs: [
      'Javascript',
      'développeur frontend',
      'design graphique',
      'C++',
      'CTO',
    ],
    active: true,
    writeInterval: 100,
    deleteInterval: 200,
    delayToWrite: 500,
    delayToDelete: 500,
  });

  useEffect(() => {
    import("../../components/AnimatedNodes/animated-nodes.ts").then(({ AnimatedNodes }) => {
      const an = new AnimatedNodes();

      an.init(document.querySelector('#top-container') as HTMLElement).then(
        () => {
          an.startRendering();
        }
      );
    });
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash;

      if (hash.length) {
        document.getElementById(hash.substring(1)).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }
  }, []);

  const onSearch = (search) => {
    navigate(`/offers?search=${search}`);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div id='home-page'>
      <section id='top-container'>
        <div className='top-gradient'>
          <div className='top-form-container'>
            <Parallax speed={20}>
              <h1>Trouvez votre prochaine mission</h1>
              <FormInputAndButton
                placeholder={autoTyper}
                noPlaceholder
                onValidate={onSearch}
              />
              <p>
                YAJA est la référence dans le recrutement Tech en France et
                ailleurs
              </p>
            </Parallax>
          </div>
        </div>
        <AnimatedNodesComponent />
      </section>

      <section id='notre-metier' className='second-section'>
        <div className='section-title'>
          <p className='section-title-description'>
            De la recherche du candidat idéal à la réalisation de vos projets
            tech, YAJA est votre partenaire de confiance.
          </p>
        </div>

        <div className='section-2-content'>
          <div className='section-2-content-cards'>
            <Card className='section-2-card left-card' onClick={() => {}}>
              <div className='text-container'>
                <p className='card-title'>Prestation intellectuelle</p>
                <p className='card-description'>
                  Chez YAJA, chaque défi technique mérite une solution sur mesure.
                  Notre équipe transforme vos besoins en code de qualité, adoptant
                  des pratiques de gestion de projet agiles ou en cascade pour une
                  livraison ponctuelle, offrant une expertise technique avancée et
                  une collaboration transparente.
                </p>
              </div>
              <img className='image-card-1' src={screenCode} />
            </Card>
            <Card className='section-2-card right-card' onClick={() => {}}>
              <div className='text-container'>
                <p className='card-title'>Cabinet de Recrutement</p>
                <p className='card-description'>
                  Chez YAJA, en tant que cabinet de recrutement spécialisé dans
                  le secteur technologique, notre mission est d'aligner les
                  talents avec les entreprises en évaluant de manière holistique
                  les compétences, l'expérience et la culture d'entreprise,
                  considérant le recrutement comme un partenariat pour la
                  croissance à long terme.
                </p>
              </div>
                <img className='image-card-2' src={blackHandShake} />
            </Card>
          </div>
        </div>
      </section>

      <section id='notre-mindset' className='fourth-section'>
        <Card className='fourth-section-card' onClick={() => {}}>
          <h3>NOTRE MINDSET</h3>
          <div className='icon-container first'>
            <FontAwesomeIcon className='icon-quote' icon={faQuoteLeft} />
          </div>
          <p>
            Parce qu'un changement de poste n'est pas anodin et peut avoir un
            impact considérable sur votre carrière, nous revendiquons : une
            approche (vraiment) technique, un accompagnement personnalisé, des
            process totalement transparents pour que vous fassiez le meilleur
            choix, un suivi même après votre placement.
          </p>
          <div className='icon-container second'>
            <FontAwesomeIcon className='icon-quote' icon={faQuoteRight} />
          </div>
        </Card>
      </section>

      <section id='comment-ca-marche' className='third-section'>
        <div className='third-section-container'>
          <div className='third-section-title'>
            <h2>Comment ça marche</h2>
          </div>
          <div className='third-section-content'>
            <ImageGallery
              items={images}
              onClick={() => {}}
              onImageLoad={() => {}}
              onSlide={() => {}}
              onPause={() => {}}
              onScreenChange={() => {}}
              onPlay={() => {}}
              infinite={true}
              showBullets={false}
              showFullscreenButton={false}
              showPlayButton={false}
              showThumbnails={true}
              showIndex={false}
              showNav={false}
              autoPlay={true}
              isRTL={false}
              thumbnailPosition={'right'}
              slideDuration={50}
              slideInterval={7000}
              slideOnThumbnailOver={true}
              additionalClass='app-image-gallery'
              useTranslate3D={true}
              useWindowKeyDown={false}
            />
            <Slidercards />
          </div>
        </div>
      </section>

      <section className='clients-testimonies-section' id='clients-testimonies'>
        <div className='clients-testimonies-section-title'></div>
        <div className='slider-container'>
          <div className='container-client'>
            <div className='description-container'>
              <p className='header' style={{ color: '#FFFFFF' }}>
                Plus de 30 clients font confiance à Yaja
              </p>
              <p className='sub-header' style={{ color: '#FFFFFF' }}>
                Découvrez pourquoi ils choisissent de faire confiance à Yaja
              </p>
              <Button
                variant='outline-secondary'
                id='button-addon2'
                className='rounded-button'
                onClick={() => {
                  navigate(`/contactus`);
                }}
              >
                <FontAwesomeIcon
                  className='arrow-icon'
                  icon={faArrowRightLong}
                />
                <span className='text-button' style={{ marginRight: '5px' }}>
                  {' '}
                  Confiez-nous votre recherche{' '}
                </span>
              </Button>
            </div>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 1,
              }}
              slidesPerView={1.5}
              loop={true}
              loopAdditionalSlides={3}
              speed={1600}
              modules={[Autoplay, Pagination, Navigation]}
              className='mySwiper'
              breakpoints={{
                500: {
                  slidesPerView: 2.1,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loopAdditionalSlides: 1,
                  loop: true,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loopAdditionalSlides: 1,
                  loop: true,
                },

                280: {
                  slidesPerView: 1.3,
                  spaceBetween: 20,
                  loopAdditionalSlides: 3,
                  loop: true,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={altessSrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={quickSignSrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={rakutenSrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={gladySrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={arrowElectronicsSrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img className='xmanna-img' src={xmannaSrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={datasolutionSrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={telaquaSrc} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card className='company-card'>
                  <img src={neo9Src} />
                </Card>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className='sixth-section'>
        <div className='section-title'>
          <h3 className='section-title-intro'>Quelques chiffres</h3>
        </div>

        <div className='sixth-section-content'>
          <Card className='box-container' onClick={() => {}}>
            <div className='icon-container'>
              <FontAwesomeIcon icon={faChartLine} />
            </div>

            {isNumbersLoading
              ? <PlaceholderLoading shape="rect" width={110} height={35} />
              : <p className='box-title'>{recruitmentsCount} recrutements</p>
            }

            <p className='box-desc'>
              C'est le nombre de personnes qui ont fait confiance à Yaja, et qui
              ont trouvé leurs Jobs.
            </p>
          </Card>

          <Card className='box-container' onClick={() => {}}>
            <div className='icon-container'>
              <FontAwesomeIcon icon={faChartLine} />
            </div>

            {isNumbersLoading
              ? <PlaceholderLoading shape="rect" width={183} height={35} />
              : <p className='box-title'>{freelancesCount} placements freelance</p>
            }

            <p className='box-desc'>
              C'est le nombre de personnes qui ont trouvé une mission freelance grâce à YAJA.
            </p>
          </Card>

          <Card
            className='box-container click-container'
            onClick={() => scrollToSection('clients-testimonies')}
          >
            <div className='icon-container'>
              <FontAwesomeIcon icon={faScroll} />
            </div>

            {isNumbersLoading
              ? <PlaceholderLoading shape="rect" width={107} height={35} />
              : <p className='box-title'>{clientsCount} Clients Ravis</p>
            }

            <p className='box-desc'>
              Ces clients heureux sont la preuve de
              l'excellence de nos services chez Yaja.
            </p>
          </Card>

          <Card
            className='box-container click-container'
            onClick={() => {
              navigate(`/offers`);
            }}
          >
            <div className='icon-container'>
              <FontAwesomeIcon icon={faStopwatch} />
            </div>

            {isOffersCountLoading
              ? <PlaceholderLoading shape="rect" width={133} height={35} />
              : <p className='box-title'>{offersCount} postes à pourvoir</p>
            }

            <p className='box-desc'>
              Des projets dans le monde entier et en full remote.
            </p>
          </Card>
        </div>
      </section>
    </div>
  );
};
